"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowMetalDetectorValidate = exports.flowMetalDetectorGetFlowName = exports.flowMetalDetectorGetGenericLabel = exports.flowMetalDetectorGetLabel = void 0;
const index_1 = require("./index");
function flowMetalDetectorGetLabel(flow) {
    var _a;
    let label = flowMetalDetectorGetGenericLabel(flow.pkgType);
    return `${label}${((_a = flow.shift) === null || _a === void 0 ? void 0 : _a.product) === index_1.Product.NaK && flow.pkgType === index_1.PkgType.SS && flow.ssLine ? " (" + flow.ssLine + ")" : ""}${flow.productFamily ? " - " + flow.productFamily.reference : ""}`;
}
exports.flowMetalDetectorGetLabel = flowMetalDetectorGetLabel;
function flowMetalDetectorGetGenericLabel(pkgType) {
    if (pkgType) {
        return `Metal Detector Check - ${pkgType}`;
    }
    return `Metal Detector Check`;
}
exports.flowMetalDetectorGetGenericLabel = flowMetalDetectorGetGenericLabel;
function flowMetalDetectorGetFlowName() {
    return "FlowMetalDetector";
}
exports.flowMetalDetectorGetFlowName = flowMetalDetectorGetFlowName;
function flowMetalDetectorValidate(flow) {
    var _a, _b;
    let errors = [];
    let warnings = [];
    const labelPkgType = "Packaging type";
    const labelProductFamily = "Product Family";
    const labelSSLine = "SS Line";
    const labelSensitivitySetting = "Sensitivity setting";
    const labelSensitivitySettingLow = "Sensitivity setting low";
    const labelSensitivitySettingHigh = "Sensitivity setting high";
    const labelTests = "Tests";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.pkgType, "pkgType", labelPkgType);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.productFamily, "productFamily", labelProductFamily);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.tests, "tests", labelTests);
    if (flow.pkgType === index_1.PkgType.Bag) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.sensitivitySetting, "sensitivitySetting", labelSensitivitySetting);
        if (flow.sensitivitySetting != null && (flow.sensitivitySetting < 0 || flow.sensitivitySetting > 999)) {
            warnings.push({
                property: "sensitivitySetting",
                message: `${labelSensitivitySetting} should be between 0 and 999.`,
            });
        }
    }
    if (flow.pkgType === index_1.PkgType.SS) {
        if (((_a = flow.shift) === null || _a === void 0 ? void 0 : _a.product) === index_1.Product.NaK) {
            warnings = (0, index_1.validationMissingValue)(warnings, flow.ssLine, "ssLine", labelSSLine);
        }
        warnings = (0, index_1.validationMissingValue)(warnings, flow.sensitivitySettingLow, "sensitivitySettingLow", labelSensitivitySettingLow);
        if (flow.sensitivitySettingLow != null && (flow.sensitivitySettingLow < 0 || flow.sensitivitySettingLow > 999)) {
            warnings.push({
                property: "sensitivitySettingLow",
                message: `${labelSensitivitySettingLow} should be between 0 and 999.`,
            });
        }
        warnings = (0, index_1.validationMissingValue)(warnings, flow.sensitivitySettingHigh, "sensitivitySettingHigh", labelSensitivitySettingHigh);
        if (flow.sensitivitySettingHigh != null && (flow.sensitivitySettingHigh < 0 || flow.sensitivitySettingHigh > 999)) {
            warnings.push({
                property: "sensitivitySettingHigh",
                message: `${labelSensitivitySettingHigh} should be between 0 and 999.`,
            });
        }
    }
    if (flow.tests) {
        for (const test of flow.tests) {
            if (!test.pass) {
                warnings.push({
                    property: "tests",
                    message: `${(_b = test.testCoupon) === null || _b === void 0 ? void 0 : _b.name} test fails`,
                });
            }
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.flowMetalDetectorValidate = flowMetalDetectorValidate;
