import { Button, Card, Form, Input, InputNumber, notification, Result, Select, Space, Spin, Popconfirm } from "antd";
import React from "react";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFlow from "../../../hooks/useFlow";
import useProductFamilies from "../../../hooks/useProductFamilies";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import {
  BusinessRole,
  flowGetStatus,
  flowIsEditable,
  flowValidateFE as flowValidate,
  IFlow,
  IFlowMetalDetector,
  PkgType,
  Status,
  Product,
  SSLine,
} from "shared/interfaces";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";
import YesNoSwitch from "../../UI/YesNoSwitch";

const { TextArea } = Input;

interface MetalDetectorFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const MetalDetectorFlowForm: React.FC<MetalDetectorFlowProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = data as IFlowMetalDetector;
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const initialValues = flow;
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";
  const { data: productFamilies, isLoading: isProductFamiliesLoading } = useProductFamilies(flow?.shift?.product);

  React.useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  const getFormTests = (initialValues, values) => {
    const tests = initialValues?.tests?.map((test) => {
      const { id, ...testWithoutId } = test;
      const testCouponId = test.testCoupon?.id || "";
      return {
        ...testWithoutId,
        pass: values[testCouponId + "_pass"],
        testCoupon: {
          id: testCouponId,
        },
      };
    });
    return tests;
  };

  if (isFlowLoading || isProductFamiliesLoading || !productFamilies || !flow) {
    return <Spin>Loading...</Spin>;
  }

  return (
    <Form
      disabled={readonly}
      form={form}
      wrapperCol={{ span: 8 }}
      labelCol={{ span: 8 }}
      labelAlign="left"
      onFinish={async (values) => {
        const initialTests = initialValues?.tests;
        initialTests?.forEach((test) => {
          const testCouponId = test?.testCoupon?.id || "";
          if (!values[testCouponId + "_pass"]) {
            form.setFields([
              {
                name: testCouponId + "_pass",
                warnings: ["All tests should pass"],
              },
            ]);
          }
        });

        const tests = getFormTests(initialValues, values);

        const flowMetalDetector: IFlowMetalDetector = {
          ...flow,
          comment: values?.comment,
          productFamily: values?.productFamily ? { id: values?.productFamily } : undefined,
          ssLine: values?.ssLine,
          tests,
        };
        if (flowMetalDetector.pkgType === PkgType.Bag) {
          flowMetalDetector.sensitivitySetting = values?.sensitivitySetting;
        } else if (flowMetalDetector.pkgType === PkgType.SS) {
          flowMetalDetector.sensitivitySettingLow = values?.sensitivitySettingLow;
          flowMetalDetector.sensitivitySettingHigh = values?.sensitivitySettingHigh;
        }

        const { warnings } = await flowValidate(flowMetalDetector);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          return;
        }
        try {
          const api = await getAPI();
          await api.put(`/flows/${flowId}`, {
            data: flowMetalDetector,
          });
          await api.put(`/flows/${flowId}/submit`);
          await refetchFlow();
          onSuccess && flow && onSuccess(flow);
          notification.success({
            message: "The metal detector check was submitted successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onValuesChange={async (changedValues, allValues) => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (hasWarnings) {
          // reset warnings
          Object.keys(allValues).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });

          const tests = getFormTests(initialValues, allValues);

          const flowMetalDetector: IFlowMetalDetector = {
            ...flow,
            ...initialValues,
            ...allValues,
            tests,
          };

          const { warnings } = await flowValidate(flowMetalDetector);
          // if we have warnings (or rather warnings), we set them in the form, the user can still submit)
          if (warnings.length > 0) {
            const initialTests = initialValues?.tests;
            initialTests?.forEach((test) => {
              const testCouponId = test?.testCoupon?.id || "";
              if (!allValues[testCouponId + "_pass"]) {
                form.setFields([
                  {
                    name: testCouponId + "_pass",
                    warnings: ["All tests should pass"],
                  },
                ]);
              }
            });

            form.setFields(
              warnings.map((warning) => ({
                name: warning.property,
                warnings: warning.messages,
              }))
            );
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
      initialValues={{
        ...initialValues,
        productFamily: initialValues?.productFamily?.id,
      }}
    >
      <Form.Item label="Product family" name="productFamily">
        <Select options={productFamilies.map(({ id, reference }) => ({ value: id, label: reference }))} disabled={readonly} />
      </Form.Item>
      {flow.shift?.product === Product.NaK && flow.pkgType === PkgType.SS && (
        <Form.Item label="SS Line" name="ssLine">
          <Select
            options={[
              { label: SSLine.West, value: SSLine.West },
              { label: SSLine.East, value: SSLine.East },
            ]}
          />
        </Form.Item>
      )}
      {flow.pkgType === PkgType.Bag && (
        <Form.Item label="Sensitivity setting" name="sensitivitySetting">
          <InputNumber controls={false} disabled={readonly} inputMode="numeric" />
        </Form.Item>
      )}
      {flow.pkgType === PkgType.SS && (
        <Form.Item label="Sensitivity setting low" name="sensitivitySettingLow">
          <InputNumber controls={false} disabled={readonly} inputMode="numeric" />
        </Form.Item>
      )}
      {flow.pkgType === PkgType.SS && (
        <Form.Item label="Sensitivity setting high" name="sensitivitySettingHigh">
          <InputNumber controls={false} disabled={readonly} inputMode="numeric" />
        </Form.Item>
      )}
      <Space direction="vertical" style={{ width: "100%" }}>
        {flow.pkgType === PkgType.Bag && (
          <Card title={"Test 1"} size="small">
            <strong>Acceptance criteria: No alarm</strong>
            {flow.tests
              ?.filter(({ testCoupon }) => testCoupon && testCoupon.withoutCoupon)
              .map((test, index) => (
                <>
                  <Form.Item label={test.testCoupon?.name} name={test.testCoupon?.id + "_pass"} initialValue={test.pass}>
                    <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
                  </Form.Item>
                </>
              ))}
          </Card>
        )}
        <Card title={flow.pkgType === PkgType.Bag ? "Test 2" : "Test coupons"} size="small">
          {flow.pkgType === PkgType.Bag && (
            <p style={{ fontWeight: "bold" }}>Acceptance criteria: Alarm activated and bag rejected (test coupons in center of bag)</p>
          )}
          {flow.pkgType === PkgType.SS && <p style={{ fontWeight: "bold" }}>Acceptance criteria: Alarm activated and coupon detected</p>}
          {flow.tests
            ?.filter(({ testCoupon }) => testCoupon && !testCoupon.withoutCoupon)
            .map((test, index) => (
              <>
                <Form.Item label={test.testCoupon?.name} name={test.testCoupon?.id + "_pass"} initialValue={test.pass}>
                  <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
                </Form.Item>
              </>
            ))}
        </Card>

        <Form.Item
          name="comment"
          label="Comment"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
        >
          <TextArea disabled={readonly} />
        </Form.Item>
      </Space>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Form.Item>
                  <Popconfirm
                    title="Save your change"
                    description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                    onConfirm={async () => {
                      try {
                        const values = form.getFieldsValue();
                        const tests = getFormTests(initialValues, values);

                        const flowMetalDetector: IFlowMetalDetector = {
                          ...flow,
                          comment: values?.comment,
                          productFamily: values?.productFamily ? { id: values?.productFamily } : undefined,
                          tests,
                        };
                        if (flowMetalDetector.pkgType === PkgType.Bag) {
                          flowMetalDetector.sensitivitySetting = values?.sensitivitySetting;
                        } else if (flowMetalDetector.pkgType === PkgType.SS) {
                          flowMetalDetector.sensitivitySettingLow = values?.sensitivitySettingLow;
                          flowMetalDetector.sensitivitySettingHigh = values?.sensitivitySettingHigh;
                        }
                        const api = await getAPI();
                        await api.put(`/flows/${flowId}/edit`, {
                          data: flowMetalDetector,
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Changes saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: "Error saving changes",
                          description: error?.message,
                        });
                      }
                    }}
                    okText="Save"
                    cancelText="Cancel"
                  >
                    <Button size="large" icon={<SaveOutlined />} type="primary">
                      Save changes
                    </Button>
                  </Popconfirm>
                </Form.Item>
              ) : (
                <>
                  <Form.Item>
                    <Button
                      disabled={flowGetStatus(flow) === Status.confirmed}
                      size="large"
                      icon={<SaveOutlined />}
                      onClick={async () => {
                        const values = form.getFieldsValue();
                        const tests = getFormTests(initialValues, values);

                        const flowMetalDetector: IFlowMetalDetector = {
                          ...flow,
                          comment: values?.comment,
                          productFamily: values?.productFamily ? { id: values?.productFamily } : undefined,
                          tests,
                        };
                        if (flowMetalDetector.pkgType === PkgType.Bag) {
                          flowMetalDetector.sensitivitySetting = values?.sensitivitySetting;
                        } else if (flowMetalDetector.pkgType === PkgType.SS) {
                          flowMetalDetector.sensitivitySettingLow = values?.sensitivitySettingLow;
                          flowMetalDetector.sensitivitySettingHigh = values?.sensitivitySettingHigh;
                        }

                        try {
                          const api = await getAPI();
                          await api.put(`/flows/${flowId}`, {
                            data: flowMetalDetector,
                          });
                          await refetchFlow();
                          notification.success({
                            message: "Metal detector check draft saved",
                          });
                          setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                        } catch (error: any) {
                          notification.error({
                            message: `Error saving metal detector check draft: ${error?.message}`,
                          });
                        }
                      }}
                    >
                      Save Draft
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                      {hasWarnings ? "Submit with warnings" : "Submit"}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};

export default MetalDetectorFlowForm;
