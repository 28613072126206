import { Space, Table, Tag, Tooltip } from "antd";
import React from "react";
import { BatchLineType, BatchType, IBatch, IShift, PkgType, Product } from "shared/interfaces";
import { Outlet, useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import "./BatchTable.css";
import { CaretDownFilled, CaretRightFilled, CheckOutlined, RedoOutlined, SyncOutlined } from "@ant-design/icons";
import { ZoomContext } from "../../../App";
import { useMatches } from "react-router-dom";
import useShift from "../../../hooks/useShift";
import BatchDetails from "./BatchDetails";
import FlagsPopover from "../../UI/FlagsPopover";
import { nicelyDisplayDateFromISOString } from "shared/utils";

interface BatchTableProps {
  shiftId: IShift["id"];
  readonly?: boolean;
  withNavigation?: boolean;
}

const BatchTable: React.FC<BatchTableProps> = ({ shiftId, readonly = false, withNavigation = true }) => {
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;
  const { data: shift } = useShift(shiftId);
  const { batchId } = useParams();
  const [expandedRowKey, setExpandedRowKey] = React.useState<number | undefined>(parseInt(batchId || ""));
  const navigate = useNavigate();
  const matches = useMatches();
  const back = matches[matches.length - 2].pathname;

  // Function to handle click events within the FlagsPopover to stop event propagation
  const handlePopoverClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {shift && (
        <Table
          className={"batch-table"}
          rowKey={(row) => row.id!}
          scroll={{ y: 1000 }}
          dataSource={
            shift?.batches
              ? shift.batches
                  .map((batch) => ({
                    totalPallets: batch.batchLines
                      ? batch.batchLines.reduce((acc, line) => (line.type === BatchLineType.regular ? acc + (line.qtyPallets ?? 0) : acc), 0)
                      : 0,
                    totalPalletsThisShift: batch.batchLines
                      ? batch.batchLines.reduce(
                          (acc, line) => (line.type === BatchLineType.regular && line.shift?.id === shift.id ? acc + (line.qtyPallets ?? 0) : acc),
                          0
                        )
                      : 0,
                    ...batch,
                  }))
                  .sort((a: IBatch, b: IBatch) => {
                    if (!a.closedAt) return -1;
                    if (!b.closedAt) return 1;
                    return dayjs(a.closedAt).isBefore(dayjs(b.closedAt)) ? 1 : -1;
                  })
              : []
          }
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () => {
                if (expandedRowKey === record.id) {
                  setExpandedRowKey(undefined);
                  withNavigation && navigate(back);
                } else {
                  setExpandedRowKey(record.id);
                  withNavigation && navigate(`batch/${record.id}`);
                }
              },
            };
          }}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              if (expanded) {
                return (
                  <CaretDownFilled
                    onClick={(e) => {
                      onExpand(record, e);
                      setExpandedRowKey(undefined);
                      withNavigation && navigate(`/shift/${shift.id}`);
                    }}
                  />
                );
              } else {
                return (
                  <CaretRightFilled
                    onClick={(e) => {
                      onExpand(record, e);
                      setExpandedRowKey(record.id);
                      withNavigation && navigate(`batch/${record.id}`);
                    }}
                  />
                );
              }
            },
            expandedRowClassName: () => "expanded-row",
            expandedRowRender: (record) => {
              if (withNavigation) {
                return <Outlet />;
              } else {
                return <BatchDetails batchId={record.id} readonly={true} />;
              }
            },
            defaultExpandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
            expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
          }}
          columns={[
            {
              title: "",
              dataIndex: "closedAt",
              key: "closedAt",
              width: (140 * parseInt(zoom)) / 100,
              render: (closedAt, record) =>
                !closedAt ? (
                  <Space size="large" onClick={handlePopoverClick}>
                    <Tooltip title="Ongoing">
                      <SyncOutlined style={{ marginLeft: 5 }} />
                    </Tooltip>
                    {record.flags && <FlagsPopover flags={record.flags} zoomRatio={zoomRatio} batchId={record.id} />}
                  </Space>
                ) : record.type === BatchType.rework ? (
                  <Space size="small" onClick={handlePopoverClick}>
                    <Tag color="orange">
                      <RedoOutlined style={{ marginRight: 5, fontSize: "0.9em" }} />
                      Rework
                    </Tag>
                    {record.flags && <FlagsPopover flags={record.flags} zoomRatio={zoomRatio} batchId={record.id} />}
                  </Space>
                ) : (
                  <Space size="large" onClick={handlePopoverClick}>
                    <Tooltip title={`Closed at ${nicelyDisplayDateFromISOString(closedAt)}`}>
                      <CheckOutlined style={{ marginLeft: 5 }} />
                    </Tooltip>
                    {record.flags && <FlagsPopover flags={record.flags} zoomRatio={zoomRatio} batchId={record.id} />}
                  </Space>
                ),
            },
            {
              title: "Number",
              dataIndex: "batchNumber",
              key: "batchNumber",
              render: (batchNumber) => <span>{batchNumber ? batchNumber : "Unknown"}</span>,
            },
            {
              title: "Pkg type",
              dataIndex: ["material", "pkgType"],
              key: "pkgType",
              render: (pkgType, record) => (
                <span>
                  {pkgType}
                  {shift.product === Product.NaK && pkgType === PkgType.SS && record.ssLine && ` (${record.ssLine})`}
                </span>
              ),
            },
            {
              title: "Material",
              dataIndex: ["material", "number"],
              key: "material",
              render: (material) => <span>{material}</span>,
            },
            {
              title: "Pallets this shift",
              dataIndex: "totalPalletsThisShift",
              key: "totalPalletsThisShift",
              render: (totalPalletsThisShift) => <span>{totalPalletsThisShift}</span>,
            },
            {
              title: "Total Pallets",
              dataIndex: "totalPallets",
              key: "totalPallets",
              render: (totalPallets) => <span>{totalPallets}</span>,
            },
          ]}
        />
      )}
    </>
  );
};

export default BatchTable;
